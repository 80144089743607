.search .input-group-text {
    background-color: #f8f9fa69;
}
.search .tags-and-input {
    width: calc(100% - 42px);
    border: var(--bs-border-width) solid var(--bs-border-color);
    background-color: #ffffff66;
    padding: .375rem .75rem .375rem .75rem;
    border-top-right-radius: var(--bs-border-radius);
    border-bottom-right-radius: var(--bs-border-radius);
}
.search .tags-and-input:has(.form-control:focus) {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.search .tags {
    margin-right: .25rem;
}

.search .form-control {
    padding: 0 0 0 .25rem;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border-radius: 0;
    border-width: 0;
    width: auto;
    min-width: 12em;
    line-height: 1.8em;
}

.search .form-control:focus {
    background-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.search .dropdown-menu {
    width: calc(100% - 40px);
    max-height: 30dvh;
    overflow: auto;
    background-color: #ffffffb8;
    left: -17px !important;
    top: 10px !important;
}

.search .dropdown-menu:not(:has(li)){
    visibility: hidden;
}

.btn-group>.btn-display-layout.btn-check:checked+.btn {
    z-index:0;
}
