.meeting-item {
    width: 256px;
    height: calc(256px * 1.414);
    /* background-color: var(--donut-gray); */
    /* https://www.joshwcomeau.com/gradient-generator?colors=eaf0f0|f8f7f7&angle=135&colorMode=lrgb&precision=20&easingCurve=0.6379310344827587|0.9958917025862069|0.375|0.00451239224137931 */
    background-image: linear-gradient(135deg,
            hsl(180deg 17% 93%) 1%,
            hsl(180deg 16% 93%) 21%,
            hsl(180deg 16% 93%) 28%,
            hsl(180deg 15% 94%) 33%,
            hsl(180deg 14% 94%) 36%,
            hsl(180deg 14% 94%) 39%,
            hsl(180deg 13% 94%) 42%,
            hsl(180deg 12% 94%) 44%,
            hsl(180deg 12% 95%) 46%,
            hsl(180deg 11% 95%) 48%,
            hsl(180deg 10% 95%) 50%,
            hsl(180deg 9% 95%) 51%,
            hsl(180deg 8% 95%) 53%,
            hsl(180deg 7% 96%) 55%,
            hsl(180deg 6% 96%) 57%,
            hsl(180deg 4% 96%) 59%,
            hsl(180deg 3% 96%) 62%,
            hsl(180deg 2% 96%) 65%,
            hsl(0deg 0% 96%) 68%,
            hsl(0deg 2% 97%) 73%,
            hsl(0deg 4% 97%) 80%,
            hsl(0deg 7% 97%) 99%);
    box-shadow: 10px 10px 10px -4px #D0DDE5;
    color: gray;
    border-radius: 50rem;
    padding: 60px 20px 35px 20px;
    position: relative;
}

.meeting-item.finished {
    border-radius: 10px;
    padding: 20px 20px 35px 20px;
    cursor: pointer;
}

.meeting-item.finished::before {
    border-radius: 10px;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.25s background-color;
}

.meeting-item.finished:hover::before {
    background-color: rgba(0, 0, 55, 0.05);
}

.meeting-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.meeting-item .meeting-minutes {
    height: 100%;
    padding: 2px 5px;
    font-size: 8px;
    line-height: 12px;
    /* max-height: 68px; */
    color: gray;
    background-color: white;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    line-clamp: 4;
    cursor: pointer;
    position: relative;
    word-wrap: normal;
    white-space: normal;
}

.context-menu .context-item {
    cursor: pointer;
}

.context-menu .context-item:hover {
    background-color: var(--donut-gray);
}

.summary-bubble {
    margin: 1px;
    border-radius: 4px;
    background-color: hsla(203, 100%, 57%, 0.2);
    box-shadow: 0px 2px 8px -3px rgb(100, 130, 155, 0.55);
    word-wrap: normal;
    white-space: normal;
}

.navtab-style-restoration {
    --bs-border-radius: 0.5rem;
    --bs-border-color: #dee2e6;
}

.navtab-style-restoration .dropdown {
    max-width: 250px;
}

.navtab-style-restoration .dropdown .nav-tabs .nav-link {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.navtab-style-restoration .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 0;
    top: 20px;
}

@media (min-width: 992px) {
    .minutes-section {
        flex: 1 0 50%;
    }

    .ai-section {
        flex: 1 1 auto;
    }

    .ai-section .form-select {
        line-height: 1;
    }

    .ai-section .form-select:focus {
        box-shadow: none;
    }
}

@media (max-width: 991px) {

    .minutes-section,
    .ai-section {
        flex: 1 1 auto;
    }
}

.language-selector {
    border: 0;
    padding: 2px;
    outline: 0;
    border-radius: 4px;
    box-sizing: border-box;
}

.language-selector:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.language-selector:focus {
    border: 2px solid hsla(203, 100%, 57%, 0.2);
    padding: 0;
}

.section-title {
    min-height: 30px;
    align-items: center;
}

.meeting-minutes pre,
.meeting-minutes code {
    font: 12px Menlo, Monaco, "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
}

.meeting-minutes pre {
    margin: 1em 0;
    font-size: 12px;
    background-color: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    line-height: 1.5em;
    color: #444;
    overflow: auto;
    -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px inset;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.meeting-minutes.thumb pre {
    font-size: 8px
}

.meeting-minutes pre code {
    padding: 0;
    font-size: 12px;
    background-color: #eee;
    border: none;
}

.meeting-minutes.thumb pre code {
    font-size: 8px
}

.meeting-minutes code {
    font-size: 12px;
    background-color: #f8f8ff;
    color: #444;
    padding: 0 .2em;
    border: 1px solid #dedede;
}

.meeting-minutes.thumb code {
    font-size: 8px
}

.meeting-minutes img {
    border: 0;
    max-width: 100%;
}

.meeting-minutes abbr {
    border-bottom: none;
}

.meeting-minutes a {
    color: #4183c4;
    text-decoration: none;
}

.meeting-minutes a:hover {
    text-decoration: underline;
}

.meeting-minutes a code,
.meeting-minutes a:link code,
.meeting-minutes a:visited code {
    color: #4183c4;
}

.meeting-minutes h2,
.meeting-minutes h3 {
    margin: 1em 0;
}

.meeting-minutes h1,
.meeting-minutes h2,
.meeting-minutes h3,
.meeting-minutes h4,
.meeting-minutes h5,
.meeting-minutes h6 {
    border: 0;
    margin: 0.5em 0;
}

.meeting-minutes h1 {
    font-size: 110%;
}

.meeting-minutes h2 {
    font-size: 120%;
}

.meeting-minutes h3 {
    font-size: 110%;
}

.meeting-minutes hr {
    border: 1px solid #ddd;
}

.meeting-minutes ul {
    margin: 0 0 0 0;
    padding-left: 10px;
}

.meeting-minutes ol {
    margin: 0 0 0 0;
    padding-left: 10px;
}

.meeting-minutes ul li,
.meeting-minutes ol li {
    margin: 2px 0;
}

.meeting-minutes ul ul,
.meeting-minutes ul ol,
.meeting-minutes ol ol,
.meeting-minutes ol ul {
    margin-top: 0;
    margin-bottom: 0;
}

.meeting-minutes blockquote {
    margin: 1em 0;
    border-left: 5px solid #ddd;
    padding-left: .6em;
    color: #555;
}

.meeting-minutes dt {
    font-weight: bold;
    margin-left: 1em;
}

.meeting-minutes dd {
    margin-left: 2em;
    margin-bottom: 1em;
}

.meeting-minutes sup {
    font-size: 0.83em;
    vertical-align: super;
    line-height: 0;
}

.meeting-minutes * {
    -webkit-print-color-adjust: exact;
}

.summary-bubble pre,
.summary-bubble code {
    font: 12px Menlo, Monaco, "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
}

.summary-bubble pre {
    margin: 1em 0;
    font-size: 12px;
    background-color: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    line-height: 1.5em;
    color: #444;
    overflow: auto;
    -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px inset;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.summary-bubble pre code {
    padding: 0;
    font-size: 12px;
    background-color: #eee;
    border: none;
}

.summary-bubble code {
    font-size: 12px;
    background-color: #f8f8ff;
    color: #444;
    padding: 0 .2em;
    border: 1px solid #dedede;
}

.summary-bubble img {
    border: 0;
    max-width: 100%;
}

.summary-bubble abbr {
    border-bottom: none;
}

.summary-bubble a {
    color: #4183c4;
    text-decoration: none;
}

.summary-bubble a:hover {
    text-decoration: underline;
}

.summary-bubble a code,
.summary-bubble a:link code,
.summary-bubble a:visited code {
    color: #4183c4;
}

.summary-bubble h2,
.summary-bubble h3 {
    margin: 1em 0;
}

.summary-bubble h1,
.summary-bubble h2,
.summary-bubble h3,
.summary-bubble h4,
.summary-bubble h5,
.summary-bubble h6 {
    border: 0;
    margin: 0.5em 0;
}

.summary-bubble h1 {
    font-size: 140%;
}

.summary-bubble h2 {
    font-size: 130%;
}

.summary-bubble h3 {
    font-size: 120%;
}

.summary-bubble hr {
    border: 1px solid #ddd;
}

.summary-bubble ul {
    margin: 0 0 0 0;
    padding-left: 1.5rem;
}

.summary-bubble ol {
    margin: 0 0 0 0;
    padding-left: 1.5rem;
}

.summary-bubble ul li,
.summary-bubble ol li {
    margin: 0.5em 0;
}

.summary-bubble ul ul,
.summary-bubble ul ol,
.summary-bubble ol ol,
.summary-bubble ol ul {
    margin-top: 0;
    margin-bottom: 0;
}

.summary-bubble blockquote {
    margin: 1em 0;
    border-left: 5px solid #ddd;
    padding-left: .6em;
    color: #555;
}

.summary-bubble dt {
    font-weight: bold;
    margin-left: 1em;
}

.summary-bubble dd {
    margin-left: 2em;
    margin-bottom: 1em;
}

.summary-bubble sup {
    font-size: 0.83em;
    vertical-align: super;
    line-height: 0;
}

.summary-bubble * {
    -webkit-print-color-adjust: exact;
}

.message-line {
    border-radius: 4px;
    padding: 4px;
    margin-top: 2px;
    position: relative;
}

.message-textarea {
    border-radius: 6px;
    background-color: #f9f9f9;
    border: 1px solid #a9a9a9;
    padding-right: 30px;
    cursor: default;
    min-height: 56px;
}

.message-textarea.mock {
    margin: 0;
    cursor: text;
}

.message-textarea.text {
    min-height: 56px;
}

.message-textarea:disabled {
    background-color: #f1f1f1;
}

.message-textarea:focus {
    cursor: text;
}

.meeting-edit-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}

.meeting-edit-button.open {
    bottom: 8px;
    right: 8px;
    color: #2196F3;
}

.meeting-edit-button.save {
    bottom: 8px;
    right: 8px;
    color: #4CAF50;
}

.meeting-edit-button.close {
    bottom: 32px;
    right: 8px;
    color: #FF5722;
}

.modal-title-right .share-btn {
    width: 86px;
    padding: 4px 5px;
    margin: 0 8px 0 0;
}

@media (max-width: 767px) {
    .modal-title-right .share-btn {
        width: 38px;
    }
}

.modal-title-right .share-btn i {
    margin: 0 2px 0 0;
}

@media (max-width: 991px) {
    .modal-title-right .share-btn {
        margin: 0 3px 0 0;
    }
}

.table {
    --bs-table-hover-bg: rgb(156 156 156 / 8%);
}

.table .sort {
    opacity: 0.3;
    cursor: pointer;
}

.table .sort.sorted {
    opacity: 1;
    color: #0897f1;
}

.table .sort:hover {
    opacity: 1;
}

tr.clickable {
    cursor: pointer;
}

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-color);
    --bs-table-bg-state: var(--bs-table-bg);
}

.table-hover>tbody>tr.clickable:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state: var(--bs-table-hover-bg);
    opacity: 1;
}

tr.clickable:hover {
    opacity: 1;
}

tr.clickable td {
    cursor: pointer;
}

.table .action .dropdown-toggle {
    border-color: transparent;
}

.table .action .dropdown-toggle::before {
    display: none;
}

.table .action .dropdown-toggle::after {
    display: none;
}

.table .action .dropdown-toggle:hover {
    --bs-btn-color: #000;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
}

.tag {
    height: 1.8em;
    margin-top: 0.2em;
    margin-right: 6px;
    font-size: 0.8em;
    margin-bottom: 0.3em;
}

.tag .close {
    display: inline-block;
    margin-left: 2px;
    cursor: pointer;
    font-size: 1em;
}

.tag .close:hover {
    cursor: pointer;
    color: #3F51B5;
}

.tag-form {
    min-width: 20em;
}

@media (max-width: 991px) {
    .tag-form {
        min-width: 10em;
        max-width: 100%;
    }
}

.tag-form .form-control {
    font-size: 1em;
    padding: 0 .75rem;
    margin-bottom: 0.4em;
}

.tag-form .btn {
    font-size: 1em;
    padding: 0 .75rem;
    margin-bottom: 0.4em;
    border-left: none;
}

.tag-form.input-group:not(.has-validation)>.btn:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: var(--bs-border-radius);
    border-bottom-right-radius: var(--bs-border-radius);
}

.user-name-form .user-id {
    min-width: 5.5em;
}

.status-view {
    line-height: 2;
    padding: 0 0.5rem;
}

.status-view.text-primary {
    background-color: #4cbaff0f;
}

.status-view.text-success {
    background-color: #1a87540f;
}

.status-view.text-warning {
    background-color: #ffc0080f;
}

.meeting-id {
    font-size: 9px;
    margin: 0;
    padding: 0;
    position: absolute;
    margin-top: -8px;
    color: #666666;
}
