.menu-big {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.menu-big .real-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.page-content {
  position: relative;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

}

.page-content>* {
  position: 'absolute';
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
}

.page-content .logo-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.25;
  transform: translateX(60%);
}

.page-content .logo-bg>img {
  width: calc(140px + 10vw);
  height: calc(140px + 10vw);
  max-width: 256px;
  max-height: 256px;
}

/* @media (min-width: 1200px) {
  .page-content .logo-bg>img {
    width: unset;
    height: unset;
  }
} */