.tippy-box[data-theme~='donut'] {
  border-radius: 0;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, .15);
  color: gray;
  box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
  font-size: unset;
}

.tippy-box[data-theme~='donut']>.tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='donut'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: white;
}

.tippy-box[data-theme~='donut'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: white;
}

.tippy-box[data-theme~='donut'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: white;
}

.tippy-box[data-theme~='donut'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: white;
}