.login-box {
  border-radius: 10px;
}

.login-box .card {
  --bs-border-radius: 8px;
}

.login-box .fa-chevron-left {
  cursor: pointer;
  color: var(--bs-tertiary-color);
}

.login-box .fa-chevron-left:hover {
  color: var(--donut-primary);
}