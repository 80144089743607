.meeting-list .meeting-list-header {
    margin-bottom: 40px;
}
@media (min-width: 768px) {
    .meeting-list .meeting-list-header {
        margin-bottom: 130px;
    }
}
.meeting-items {
    top: 0;
}
@media (min-width: 768px) {
    .meeting-items.meeting-items-grid {
        top: -80px;
    }
}
