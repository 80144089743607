:root {
  --donut-primary: #4DBAFF;
  --donut-primary-light: #65C3F6;
  --donut-primary-dark: #2d96d8;
  --donut-primary-50: #4DBAFF88;
  --donut-primary-20: #4DBAFF44;
  --donut-gray: #EDEDED;
  --donut-gray-2: #F4F4F4;
  --donut-text: #9C9C9C;
  --donut-text-dark: #7C7C7C;

  /* override bootstrap color */
  --bs-border-color: gray;
  --bs-border-radius: 100em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}

.no-mouse {
  pointer-events: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-primary {
  color: var(--donut-primary) !important;
}

.text-donut {
  color: var(--donut-text) !important;
}

.text-donut-dark {
  color: var(--donut-text-dark) !important;
}

.bg-primary {
  background-color: var(--donut-primary) !important;
}

.bg-grad-primary {
  background-image: linear-gradient(90deg, var(--donut-primary-dark) 0%, var(--donut-primary-light) 100%);
}

.donut-box {
  background-color: var(--donut-gray-2);
  box-shadow: 0px 0px 10px 0px rgb(172, 172, 172);
}

.donut-box-thick {
  background-color: var(--donut-gray);
  box-shadow: 0px 2px 8px 5px #B3C6CC;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--donut-primary);
  --bs-btn-border-color: var(--donut-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--donut-primary-dark);
  --bs-btn-hover-border-color: var(--donut-primary-dark);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--donut-primary-dark);
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--donut-primary);
  --bs-btn-disabled-border-color: var(--donut-primary);
}

.btn-outline-primary {
  --bs-btn-color: var(--donut-primary);
  --bs-btn-border-color: var(--donut-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-bg: #fff;
  --bs-btn-hover-bg: var(--donut-primary);
  --bs-btn-hover-border-color: var(--donut-primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--donut-primary);
  --bs-btn-active-border-color: var(--donut-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--donut-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--donut-primary);
  --bs-gradient: none;
}