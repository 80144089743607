.avatar-container .a-img {
  flex: 1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 32px;
  /* https://www.joshwcomeau.com/gradient-generator?colors=2d96d8|4dbaff&angle=225&colorMode=lrgb&precision=4&easingCurve=0.6336206896551724|1.05|0.45689655172413796|-0.05 */
  background-image: linear-gradient(225deg,
      hsl(203deg 69% 51%) 8%,
      hsl(203deg 74% 54%) 39%,
      hsl(203deg 79% 57%) 49%,
      hsl(203deg 85% 60%) 57%,
      hsl(203deg 92% 63%) 67%,
      hsl(203deg 100% 65%) 93%);
}