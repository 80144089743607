.text-link {
  cursor: pointer;
}

.text-link:hover {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.5;
}

.home-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-page .header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  /* flex-grow: 0; */
}

.home-page .logo {
  height: 48px;
}

.home-page .content {
  position: relative;
  flex: 1;
  width: 100%;
}

.home-page .content>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

input#allocatedTime::-webkit-slider-runnable-track {
  background-color: var(--donut-gray);
}

#createMeetingTabs {
  width: calc(100% + 32px);
  margin-left: -16px;
}
#createMeetingTabs .nav-item:first-child {
  margin-left: 6px;
}

.list-group-container {
  height: 150px;
  overflow-y: scroll;
  border-radius: 16px;
  border: 1px solid rgb(128, 128, 128);
}

.list-group-container .list-group-item {
  border-left: none;
  border-right: none;
  padding: 0;
}

.list-group-container .list-group-item label {
  display: block;
  padding: 3px 8px;
  font-size: 1rem;
  cursor: pointer;
}

.list-group-container .list-group-item label:has(:disabled) {
  cursor: default;
  background-color: #eeeeee;
}

.list-group-container .list-group-item label:hover {
  background-color: rgba(45, 150, 216, 0.18);
}

.list-group-container .list-group-item label:has(:disabled):hover {
  background-color: #eeeeee;
}

.list-group-container .list-group-item label .form-check-input {
  margin-right: 4px;
}

.list-group-container .list-group-item label img {
  width: 1.4em;
  margin-right: 4px;
}

.list-group-container .list-group-item:first-child {
  border-top: none;
}

.list-group-container .list-group-item:last-child {
  border-bottom: none;
}

.bg-flag {
  background-position: 10px center;
  background-size: 2em;
  background-color: rgb(248, 248, 248);
  padding-left: 50px;
  font-size: 1rem;
}

.notify {
  border: none !important;
}
.unread {
  background-color: #e91e631a;
}
.unread:hover {
  background-color: rgba(233, 30, 99, 0.18);
}
.notify-list.dropdown-menu.show {
}
.notify-list.dropdown-menu.show ul{
  list-style: none;
  padding: 0;
  max-height: 50dvh;
  overflow-y: scroll;
  max-width: 100dvw;
  margin: 0;
}
.notify-list.dropdown-menu.show:before{
  content: "";
  position: absolute;
  top: -11px;
  right: 16px;
  width: 0;
  height: 0;
  margin: 0;
  border-style: solid;
  border-color: #c3c3c3 transparent transparent transparent;
  border-width: 11px 11px 0 11px;
  rotate: 180deg;
}
.notify-list.dropdown-menu.show:after{
  content: "";
  position: absolute;
  top: -10px;
  right: 17px;
  width: 0;
  height: 0;
  margin: 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  border-width: 10px 10px 0 10px;
  rotate: 180deg;
}

.notify-list.dropdown-menu.show li{
  border-top: 1px solid #9C9C9C;
}
.notify-list.dropdown-menu.show li:first-child{
  border-top: none;
}
.notify-list.dropdown-menu.show li > *{
  padding-top: 8px;
  padding-bottom: 8px;
}

.notify-list.dropdown-menu.show .error{
  background: rgba(255, 35, 152, 0.06);
}

.notify-list.dropdown-menu.show .dropdown-item-text {
  cursor: default;
}

.bg-select {
  background-color: rgb(248, 248, 248) !important;
}

.modal-title {
  width: 100%;
}
.modal-title:has(+ .modal-title-right) {
  width: calc(100% - 110px);
}

@media (min-width: 992px) {
  .modal-title-right {
    position: absolute;
    right: 40px;
  }
}

@media (max-width: 991px) {
  .modal-title:has(+ .modal-title-right) {
    width: calc(100% - 123px);
  }

  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y)* .5) calc(var(--bs-modal-header-padding-x)* .5);
    margin: calc(-.5* var(--bs-modal-header-padding-y)) 0 calc(-.5* var(--bs-modal-header-padding-y)) 0;
  }
}

@media (max-width: 767px) {
  .modal-title:has(+ .modal-title-right) {
    width: calc(100% - 78px);
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width:100%!important;
  }
  .w-sm-75 {
    width:75%!important;
  }
  .w-sm-50 {
    width:50%!important;
  }
  .w-sm-25 {
    width:25%!important;
  }
  .w-sm-auto {
    width:auto!important;
  }
  .h-sm-100 {
    height:100%!important;
  }
  .h-sm-75 {
    height:75%!important;
  }
  .h-sm-50 {
    height:50%!important;
  }
  .h-sm-25 {
    height:25%!important;
  }
  .h-sm-auto {
    height:auto!important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width:100%!important;
  }
  .w-md-75 {
    width:75%!important;
  }
  .w-md-50 {
    width:50%!important;
  }
  .w-md-25 {
    width:25%!important;
  }
  .w-md-auto {
    width:auto!important;
  }
  .h-md-100 {
    height:100%!important;
  }
  .h-md-75 {
    height:75%!important;
  }
  .h-md-50 {
    height:50%!important;
  }
  .h-md-25 {
    height:25%!important;
  }
  .h-md-auto {
    height:auto!important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width:100%!important;
  }
  .w-lg-75 {
    width:75%!important;
  }
  .w-lg-50 {
    width:50%!important;
  }
  .w-lg-25 {
    width:25%!important;
  }
  .w-lg-auto {
    width:auto!important;
  }
  .h-lg-100 {
    height:100%!important;
  }
  .h-lg-75 {
    height:75%!important;
  }
  .h-lg-50 {
    height:50%!important;
  }
  .h-lg-25 {
    height:25%!important;
  }
  .h-lg-auto {
    height:auto!important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width:100%!important;
  }
  .w-xl-75 {
    width:75%!important;
  }
  .w-xl-50 {
    width:50%!important;
  }
  .w-xl-25 {
    width:25%!important;
  }
  .w-xl-auto {
    width:auto!important;
  }
  .h-xl-100 {
    height:100%!important;
  }
  .h-xl-75 {
    height:75%!important;
  }
  .h-xl-50 {
    height:50%!important;
  }
  .h-xl-25 {
    height:25%!important;
  }
  .h-xl-auto {
    height:auto!important;
  }
}
