.setting-block {
  padding-top: calc(var(--bs-gutter-x) * .5);
  padding-bottom: calc(var(--bs-gutter-x) * .5);
}

.setting-block+.setting-block {
  border-top: 1px solid var(--bs-border-color);
}

.setting-block>* {
  width: 100%;
}

.setting-block .title {
  cursor: pointer;
  user-select: none;
}

.setting-block .title:hover {
  text-decoration: underline;
}