.chat-messages .chat-balloon.border{
  border-color: #adb5bd3b !important;
}
.chat-messages .chat-balloon.border.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.chat-messages .bg-light {
  --bs-bg-opacity: 0.5;
}

.chat-messages .bg-body-secondary {
  --bs-bg-opacity: 0.5;
}

.loading-dots {
  display: inline-flex;
  align-items: center;
}

.loading-dot {
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background: #666;
  border-radius: 50%;
  animation: dot-pulse 1.5s infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-pulse {
  0%, 100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.chat-balloon {
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.05);
}

.chat-balloon > p:last-child {
  margin-bottom: 0;
}

.chat-balloon .markdown-body > p:last-child {
  margin-bottom: 0;
}

.chat-balloon .markdown-body {
  color: #000 !important;
  background: transparent !important;
}

.chat-balloon .markdown-body pre,
.chat-balloon .markdown-body code{
  font:12px Menlo, Monaco, "DejaVu Sans Mono", "Bitstream Vera Sans Mono",monospace;
}
.chat-balloon .markdown-body pre {
  margin:1em 0;
  font-size:12px;
  background-color:#eee;
  border:1px solid #ddd;
  padding:5px;
  line-height:1.5em;
  color:#444;
  overflow:auto;
  -webkit-box-shadow:rgba(0,0,0,0.07) 0 1px 2px inset;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;border-radius:3px;
}
.chat-balloon .markdown-body pre code {
  font-size: 16px;
  background-color: #f8f8ff;
  color: #444;
  padding: 0 .2em;
  border: 1px solid #dedede;
}
.chat-balloon .markdown-body code {
  font-size: 16px;
  background-color: #f8f8ff;
  color: #444;
  padding: 0 .2em;
  border: 1px solid #dedede;
}
.chat-balloon .markdown-body img{border:0;max-width:100%;}
.chat-balloon .markdown-body abbr{border-bottom:none;}
.chat-balloon .markdown-body a{color:#4183c4;text-decoration:none;}
.chat-balloon .markdown-body a:hover{text-decoration:underline;}
.chat-balloon .markdown-body a code,
.chat-balloon .markdown-body a:link code,
.chat-balloon .markdown-body a:visited code{
  color:#4183c4;
}
.chat-balloon .markdown-body h2,.chat-balloon .markdown-body h3{
  margin:1em 0;
}
.chat-balloon .markdown-body h1,
.chat-balloon .markdown-body h2,
.chat-balloon .markdown-body h3,
.chat-balloon .markdown-body h4,
.chat-balloon .markdown-body h5,
.chat-balloon .markdown-body h6{
  border:0;
  margin: 0.25em 0;
}
.chat-balloon .markdown-body h1{
  font-size:130%;
}
.chat-balloon .markdown-body h2{
  font-size:120%;
}
.chat-balloon .markdown-body h3{
  font-size:110%;
}
.chat-balloon .markdown-body hr{
  border:1px solid #ddd;
}
.chat-balloon .markdown-body ul{
  margin:0 0 0 0;
  padding-left: 1.5rem;
}
.chat-balloon .markdown-body ol{
  margin:0 0 0 0;
  padding-left: 1.5rem;
}
.chat-balloon .markdown-body ul li,
.chat-balloon .markdown-body ol li{
  margin: 0.5em 0;
}
.chat-balloon .markdown-body ul ul,
.chat-balloon .markdown-body ul ol,
.chat-balloon .markdown-body ol ol,
.chat-balloon .markdown-body ol ul{
  margin-top:0;
  margin-bottom:0;
}
.chat-balloon .markdown-body blockquote{
  margin:1em 0;
  border-left:5px solid #ddd;
  padding-left:.6em;color:#555;
}
.chat-balloon .markdown-body dt{
  font-weight:bold;
  margin-left:1em;
}
.chat-balloon .markdown-body dd{
  margin-left:2em;
  margin-bottom:1em;
}
.chat-balloon .markdown-body sup {
  font-size: 0.83em;
  vertical-align: super;
  line-height: 0;
}
.chat-balloon .markdown-body * {
  -webkit-print-color-adjust: exact;
  white-space: normal;
}
