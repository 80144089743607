/* .menu-content {} */

.menu-content .user-info {
  position: relative;
  color: var(--donut-text);
  background-color: var(--donut-gray-2);
  font-weight: bold;
}

.menu-content .navigation {
  font-size: 1.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--donut-text);
  background-color: var(--donut-gray-2);
  /* font-weight: bold; */
}

.menu-content .padding {
  padding-top: 60%;
  position: relative;
}

.menu-content .padding>* {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--donut-gray-2);
}

.menu-content .padding>*:first-child {
  left: 0;
  width: 40%;
}

.menu-content .padding>*:last-child {
  left: 40%;
  width: 60%;
  border-bottom-right-radius: 100%;
}

.menu-content .menu-item {
  cursor: pointer;
}

.menu-content .menu-item:not(.active):hover {
  opacity: 0.5;
}

.menu-content .menu-item .active-bar {
  padding: 1px 0;
  flex: 1;
}