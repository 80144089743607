.custom-file-input-fake, .custom-file-input-fake .form-control {
  cursor: pointer;
}
.custom-file-input-fake .form-control:focus {
  box-shadow: none;
  border-color: inherit;
}
.custom-file-input {
  display: none;
}
