:root {
  --bs-border-radius: 1em;
}

.btn-purple {
  --bs-btn-color: #000;
  --bs-btn-bg: #6f42c1;
  --bs-btn-border-color: #6f42c1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8c68cd;
  --bs-btn-hover-border-color: #a025f2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8c68cd;
  --bs-btn-active-border-color: #a025f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #6f42c1;
  --bs-btn-disabled-border-color: #6f42c1;
}

/* 辞書機能コンテナスタイル */
.dictionary-list-container {
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.375rem;
}

/* 辞書機能（共有辞書）コンテナスタイル */
.parent-dictionary-list-container {
  opacity: 0.8;
}

.dictionary-list-container::-webkit-scrollbar {
  width: 8px;
}

.dictionary-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dictionary-list-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dictionary-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
